'use client';

import React from 'react';
import { Command } from 'lucide-react';

type KeyboardShortcutProps = {
  shortcut: string;
  className?: string;
};

export function KeyboardShortcut({
  shortcut,
  className = '',
}: KeyboardShortcutProps) {
  const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
  const keys = shortcut.split('+');

  return (
    <div
      className={`inline-flex items-center space-x-1 text-primary/50 ${className}`}
    >
      {keys.map((key, index) => (
        <React.Fragment key={key}>
          <kbd className="rounded-md border border-background bg-background px-1 py-1 text-xs">
            {key.toLowerCase() === 'meta' ? (
              <span className="flex items-center">
                {isMac ? <Command className="h-4 w-4 stroke-2" /> : 'Ctrl'}
              </span>
            ) : (
              key
            )}
          </kbd>
          {index < keys.length - 1 && <span>+</span>}
        </React.Fragment>
      ))}
    </div>
  );
}
