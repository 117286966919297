'use client';

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { HomeIcon, SearchIcon } from 'lucide-react';
import Logo from 'public/logo.svg';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';
import { Button } from 'src/components/Button/Button';
import { KeyboardShortcut } from 'src/components/keyboard-shortcut/keyboard-shortcut';
import { useValidatedParams } from 'src/hooks/useParsedParams';
import { Translated } from 'src/i18n/TranslationsProvider';
import { MapCurrentEditors } from 'src/modules/MapLiveUpdates/MapCurrentEditors';
import { useModalActions } from 'src/store/modalsStore/modalsStore';
import { useUser } from 'src/store/user/userStore';
import { cn } from 'src/utils/cn';
import { z } from 'zod';

const paramsSchema = z.object({
  mapSlug: z.string().optional(),
});

export function NavbarWrapper(props: React.PropsWithChildren) {
  const isMobile = useIsMobile();
  const user = useUser();
  const { mapSlug } = useValidatedParams(paramsSchema);
  const isMapView = mapSlug !== undefined;
  const { openModal: openSearchModal } = useModalActions('search');

  const isMobileMap = isMobile && isMapView;
  const iconSize = isMobile ? 32 : 24;

  const navBarRef = React.useCallback(
    (node: HTMLElement | null) => {
      if (!node) {
        return;
      }
      const pt = window.getComputedStyle(node).paddingTop;
      if (pt === '0' || pt === '0px') {
        node.style.paddingTop = '0.5rem';
      }
    },
    [isMobile]
  );

  return (
    <nav
      ref={navBarRef}
      data-testid="navbar"
      className={cn(
        'fixed inset-x-0 top-0 z-10',
        'flex h-navbar w-full items-center gap-2',
        'px-4 pb-2 pt-[env(safe-area-inset-top)] sm:pt-2',
        {
          'bg-background/50 backdrop-blur': !isMobileMap,
        }
      )}
    >
      <div className="h-full w-8 flex-shrink-0 max-lg:hidden"></div>
      <div
        data-testid="navbar-left"
        className="flex items-center justify-start gap-4 sm:w-full"
      >
        {isMapView && isMobile && (
          <Link
            href={user ? '/my-maps' : '/'}
            passHref
            prefetch={false}
            className="flex items-center"
          >
            <Button rounded square variant="secondary">
              <HomeIcon width={iconSize} height={iconSize} />
            </Button>
          </Link>
        )}
        {!isMobileMap && (
          <Link
            href={user ? '/my-maps' : '/'}
            passHref
            prefetch={false}
            className="flex items-center"
          >
            <Image src={Logo} className="h-14 w-auto" alt="Logo" />
          </Link>
        )}
      </div>
      <div
        data-testid="navbar-center"
        className="flex flex-1 justify-center sm:min-w-[240px] xl:min-w-[480px]"
      >
        {isMapView && (
          <Button
            rounded={isMobile}
            padded={false}
            variant="secondary"
            onClick={openSearchModal}
            className="w-full items-center justify-around px-4 py-2 normal-case text-primary/50 hover:text-primary hover:shadow-md focus:outline-none dark:hover:text-primary sm:bg-backgroundLight sm:hover:bg-backgroundLight"
          >
            <SearchIcon
              height={24}
              width={24}
              className="stroke-[2px] only:mr-0"
              style={{ height: iconSize }}
            />
            <div className="text-base">
              <Translated id="search.placeholder" />
            </div>
            <div className="ml-auto hidden lg:block">
              <KeyboardShortcut shortcut="Meta+K" />
            </div>
          </Button>
        )}
      </div>
      <div
        data-testid="navbar-right"
        className="flex items-center justify-end gap-4 sm:w-full"
      >
        {props.children}
      </div>
      <div className="h-full w-8 flex-shrink-0 max-lg:hidden">
        {isMapView && <MapCurrentEditors />}
      </div>
    </nav>
  );
}
